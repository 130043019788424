import React from 'react'
import { graphql } from 'gatsby'
import CTASection from '../components/global/CTASection'
import parse from 'html-react-parser'
import Texture from '../components/animation/Texture'
import Card from '../components/reuseable/Card'
import {Styling} from '../styles/products/styled'
import { Helmet } from 'react-helmet'

export default function Products({data}) {
  const products = data.products.nodes;
  return (
    <>
    <Helmet>
        <title>{data.content.seo.title}</title>
        <meta name="description" content={data.content.seo.metaDesc} />
        <meta name="og:description" content={data.content.seo.metaDesc} />
        <meta property="og:title" content={data.content.seo.title} />          
    </Helmet>
    <Styling>
    <div className="offpage-translate offpage-translate-bg"></div>
      <div className="offpage-translate">
        <div className="products-wrapper">
          <h1 className="libre large title">{data.content.title}</h1>
            <ul className="products">
              {products.map(product => {
                return(
                  <li className="product_card">
                    <Card 
                      button={false} 
                      link={product.link} 
                      title={product.productFields.archiveTitle} 
                      image={product.customHeroImage.heroImage}
                    />
                  </li>
                )
              })
              }
            </ul>
        </div>

        <CTASection ctaType={'chat'} />
        
        <div className="defaultTextArea">
          <h1 className="libre large title">{data.content.defaultPageFields.contentTitle}</h1>
          <div className="text">{parse(data.content.defaultPageFields.contentText)}</div>
        </div>
      </div>
    <Texture 
    className='texture full' 
    opacity={0.05}  
    position='absolute'
    bottom="0"
    height={''}
    top="0"
    watchScroll={false}
    />
    </Styling>

    </>
  )
}


export const query = graphql`
  {
    content: wpPage(slug:{eq:"products"}) {
      defaultPageFields {
        contentText
        contentTitle
      }
      title
      seo {
        title
        metaDesc
      }
      customHeroImage {
        heroImage {
          localFile{
            publicURL
          }
        }
      }
    }
    products: allWpProduct(sort: {fields: menuOrder}) {
      nodes {
        link
        customHeroImage {
          heroImage {
            localFile{
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        productFields {
          archiveTitle
        }
      }
    }
  }
`