import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
margin-top:63px;
z-index: 100;
position: relative;

    .texture{
    z-index:200 !important;
    }

    .products-wrapper{
    padding:2rem;
    position: relative;
    
        @media only screen and (min-width: 450px) {
            padding:2rem 4rem 4rem 4rem;
        }
    
        @media only screen and (min-width: 1024px) {
        padding:8rem 10rem 4rem 10rem;        
        }

        .title{
        margin-bottom: 4rem;
        }
        
    }

    .products{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    
        @media only screen and (min-width: 610px) {
        grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (min-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
        }

    }

    .defaultTextArea{
    padding:4rem 2rem;

        @media only screen and (min-width: 450px) {
        padding:4rem;
        }
    
        @media only screen and (min-width: 1024px) {
        padding:8rem 10rem;
        }

        .title{
        margin-bottom: 4rem;
        }

        .text{
        max-width: 100%;
        ${({theme}) => theme.fontSize('16')}  
        ${({theme}) => theme.fontSizeLineHeight('16', '1.5')}  

            @media only screen and (min-width: 1024px) {
            max-width: 50%;
            }


            p{
            margin-bottom: 2rem;
            }
        }
    }

`

export { Styling }